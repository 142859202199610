import style from "./style.module.css";

export default function Reportes(){
    return (
    <div>
        <div className={style.divTitle}>
            <div>
                <h1 className="title text-light px-3 pt-1 mb-0">Reportes</h1>
            </div>
        </div>
    </div>
    );
}